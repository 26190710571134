import { type } from '@ngrx/signals';
import { entityConfig } from '@ngrx/signals/entities';

import { Status } from '../../../../enums/status.enum';
import { BuildingCard } from '../models3d.interface';

export const models3DConfig = entityConfig({
  entity: type<BuildingCard>(),
  collection: 'models3d',
  selectId: (item) => item.buildingId,
});

export interface Models3DState {
  status: Status;
  error: string;
}

export type BuildingCardEntities = Record<BuildingCard['buildingId'], BuildingCard>;
