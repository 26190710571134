import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { Injectable, computed, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withState } from '@ngrx/signals';
import { setEntities, updateEntities, updateEntity, withEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap, tap } from 'rxjs';

import { ObjectCardService } from '../../../../components/object-card/object-card.service';
import { Status } from '../../../../enums/status.enum';
import { BackendEventsService } from '../../../../services/backend-events.service';
import { OnBuildingActiveStateChangedParam, SUCCESS } from '../../../../types/jsonrpc.interface';
import { minLoadTime } from '../../../../utils/rxjs-custom-pipes/min-load-time.util';
import { ActivateBuildings } from '../models3d.interface';
import { Models3DState, models3DConfig } from './models3d.store.type';

/**
 * Сервис хранилища моделей 3D.
 * @constructor
 * @param {Object} options - Опции для конфигурации хранилища.
 * @param {boolean} options.protectedState - Флаг защиты состояния.
 * @param {string} options.devtools - Наименование для DevTools.
 * @param {Models3DState} options.state - Начальное состояние хранилища моделей 3D.
 * @param {Object} options.entities - Сущности моделей 3D.
 */
@Injectable({ providedIn: 'root' })
export class Models3dStoreService extends signalStore(
  { protectedState: false },
  withDevtools('models3d'),
  withState<Models3DState>({ status: Status.UNINITIALIZED, error: '' }),
  withEntities(models3DConfig),
) {
  /**
   * Функция, которая вычисляет, загружается ли данные.
   * @returns {boolean} Возвращает true, если статус равен LOADING, иначе false.
   */
  isLoading = computed(() => this.status() === Status.LOADING);

  /**
   * Реактивная переменная, которая вычисляет, является ли текущий статус неинициализированным.
   *
   * Это вычисляемое свойство возвращает булево значение, указывающее, равен ли `status` значению `Status.UNINITIALIZED`.
   *
   * @returns {boolean} Истина, если статус неинициализирован, в противном случае ложь.
   */
  isUninitialized = computed(() => this.status() === Status.UNINITIALIZED);

  /**
   * Проверяет, загружены ли данные.
   * @returns {boolean} Возвращает true, если данные загружены, иначе false.
   */
  isLoaded = computed(() => this.status() === Status.LOADED);

  /**
   * Сервис для работы с событиями бэкенда.
   * @type {BackendEventsService}
   */
  readonly #backendEventsService = inject(BackendEventsService);

  /**
   * Сервис для работы с объектами карты.
   * @type {ObjectCardService}
   */
  readonly #objectCardService = inject(ObjectCardService);

  /**
   * Получает здания и обновляет состояние приложения.
   * @returns {void}
   */
  readonly getBuildings = rxMethod<void>(
    pipe(
      tap(() => patchState(this, { status: Status.LOADING })),
      switchMap(() =>
        this.#backendEventsService.getCurrentBuildings().pipe(
          tapResponse({
            next: (data) => {
              const sortedBuildings = data.toSorted((a, b) => a.name.localeCompare(b.name));
              patchState(this, setEntities(sortedBuildings, models3DConfig));
            },
            error: console.error,
            finalize: () => patchState(this, { status: Status.LOADED }),
          }),
        ),
      ),
    ),
  );

  /**
   * Метод для активации зданий.
   *
   * @param {ActivateBuildings} data - Данные для активации зданий.
   * @returns {Observable<SUCCESS>} - Observable с результатом активации зданий.
   */
  readonly activateBuildings = rxMethod<ActivateBuildings>(
    pipe(
      switchMap((data: ActivateBuildings) => {
        if (!data.bShow && data.buildingsId.some((buildingId) => buildingId === this.#objectCardService.additionData().buildingId)) {
          this.#objectCardService.closeObjectEvent$.next();
        }

        return minLoadTime<SUCCESS>(this.#backendEventsService.activateBuildings(data)).pipe(
          tapResponse({
            next: () => {
              patchState(this, updateEntities({ ids: data.buildingsId, changes: () => ({ bActive: data.bShow }) }, models3DConfig));
            },
            error: console.error,
          }),
        );
      }),
    ),
  );

  /**
   * Изменяет активное состояние здания.
   * @param {OnBuildingActiveStateChangedParam} params - Параметры изменения активного состояния здания.
   * @returns {void}
   */
  buildingActiveStateChange({ buildingId, bActive }: OnBuildingActiveStateChangedParam): void {
    patchState(this, updateEntity({ id: buildingId, changes: { bActive } }, models3DConfig));
  }
}
